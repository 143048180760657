const QUERY_PARAM_TRAIT_PREFIX = 'ajs_trait_';
const QUERY_PARAM_PROPERTY_PREFIX = 'ajs_prop_';
const QUERY_PARAM_ANONYMOUS_ID_KEY = 'ajs_aid';
const QUERY_PARAM_USER_ID_KEY = 'ajs_uid';
const QUERY_PARAM_TRACK_EVENT_NAME_KEY = 'ajs_event';

export {
  QUERY_PARAM_TRAIT_PREFIX,
  QUERY_PARAM_PROPERTY_PREFIX,
  QUERY_PARAM_ANONYMOUS_ID_KEY,
  QUERY_PARAM_USER_ID_KEY,
  QUERY_PARAM_TRACK_EVENT_NAME_KEY,
};
