const DEFAULT_XHR_TIMEOUT_MS = 10 * 1000; // 10 seconds
const DEFAULT_COOKIE_MAX_AGE_MS = 31536000 * 1000; // 1 year
const DEFAULT_SESSION_TIMEOUT_MS = 30 * 60 * 1000; // 30 minutes
const MIN_SESSION_TIMEOUT_MS = 10 * 1000; // 10 seconds
const DEFAULT_DATA_PLANE_EVENTS_BUFFER_TIMEOUT_MS = 10 * 1000; // 10 seconds
const DEBOUNCED_TIMEOUT_MS = 250; // 250 milliseconds

export {
  DEFAULT_XHR_TIMEOUT_MS,
  DEFAULT_COOKIE_MAX_AGE_MS,
  DEFAULT_SESSION_TIMEOUT_MS,
  MIN_SESSION_TIMEOUT_MS,
  DEFAULT_DATA_PLANE_EVENTS_BUFFER_TIMEOUT_MS,
  DEBOUNCED_TIMEOUT_MS,
};
